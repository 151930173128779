import React from 'react';
import { observer } from 'mobx-react';
import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, AppTypes } from '../../Services/AppService';
import './../../Styles/Variable/Components/ChatbotComponent.scss';

interface IChatbotComponentProps {

}


@NeoModel
class ChatbotComponentVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(AppTypes.Neo.TaskRunner),
    ) {
        super(taskRunner);
    }
}

@observer
export default class ChatbotComponent extends Views.ViewComponentBase<IChatbotComponentProps, ChatbotComponentVM> {

    constructor(props: IChatbotComponentProps) {
        super(props, ChatbotComponentVM);
    }

    public render() {
        
        const chatbotURL = AppService.get(AppTypes.Clients.Services.ChatbotService).chatbotURL;

        return (
            <div>
                {chatbotURL !== '' &&
                    <iframe
                        src={chatbotURL}
                        className="chatbot"
                        title='Chatbot'
                        id='chatbot'
                        style={{ width: '70px', height: '70px' }}>
                    </iframe>}
            </div>
        );
    }
}