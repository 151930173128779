import { EnumHelper } from "@singularsystems/neo-core";

export enum AwardConditionType
{
        /// <summary>
        /// The condition can only be processed once forfeiting or topping-up units of the vesting tranches only one time.
        /// </summary>
        ForfeitureOrTopUp = 1,

        /// <summary>
        /// The condition can only be processed once forfeiting units from the vesting tranches only one time.
        /// </summary>
        Forfeiture = 2,

        /// <summary>
        /// The condition can only be processed once topping-up units into the vesting tranches only one time.
        /// </summary>
        TopUp = 3,

        /// <summary>
        /// Not sure what the comment should be.
        /// </summary>
        UnitConversion = 4,
        
}

EnumHelper.decorateEnum(AwardConditionType, decorator => {
        decorator.describe(AwardConditionType.ForfeitureOrTopUp, "Forfeiture or top up");
        decorator.describe(AwardConditionType.Forfeiture, "Forfeiture");
        decorator.describe(AwardConditionType.TopUp, "Top up");
        decorator.describe(AwardConditionType.UnitConversion, "Unit conversion");
    });