import React from 'react';
import { EnumHelper, ModalUtils } from '@singularsystems/neo-core';
import { Neo } from '@singularsystems/neo-react';
import PortfolioVM from '../PortfolioVM';
import { BalancesGroupedGrid } from './BalancesGroupedGrid';
import { PendingTradeRequestsComponent } from '../../Components/TradeRequests/PendingTradeRequestsComponent';
import { VestingType } from '../../../Models/Portfolio/IncentiveGroup';
import { observer } from 'mobx-react';
import { TemplateLink } from './TemplateLink';

interface IBalancesComponentProps {
    viewModel: PortfolioVM;
    onCalculateClick: (forTrading: boolean) => void;
}

@observer
export class BalancesComponent extends React.Component<IBalancesComponentProps> {

    private showTradeCalculator() {
        if (this.props.viewModel.vestingType === VestingType.UnvestedOnly) {
            ModalUtils.showMessage("Calculator", "Please change the vesting type filter to continue.");
        } else {
            if (!this.props.viewModel.incentiveGroups.find(c => c.hasSelectedGroups())) {
                ModalUtils.showMessage("Calculator", "Please select one or more awards before clicking continue.");
            } else {
                this.props.onCalculateClick(true);
            }
        }
    }

    public render() {
        const viewModel = this.props.viewModel,
            portfolioData = viewModel.portfolioData,
            noAwards = portfolioData && portfolioData.trancheBalances.length === 0;

        return (
            <div>
                <h2>My Portfolio Details</h2>

                {noAwards &&
                    <div className="mt-4">
                        You do not have any awards yet.
                    </div>}

                {!noAwards &&
                    <div>
                        <p className="my-3">
                            View the details of your awards and calculate potential trades. You can confirm your trade(s) in a later step if you choose to proceed.<br />
                            <TemplateLink templateTypeKey="TradeProcessExplanation">
                                Click here to find out more about the trade process.
                            </TemplateLink>
                        </p>

                        <div className="flex-container flex-v-end">
                            <Neo.FormGroup label="Show portfolio by:" bind={viewModel.meta.vestingType} select={{ items: EnumHelper.asList(VestingType) }} editorClassName="highlight"
                                editorStyle={{ width: 180, paddingRight: 0 }} />

                            <div style={{ flex: "auto", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", textAlign: "right" }}>
                                {portfolioData && portfolioData.settings.allowParticipantPortfolioDownload &&
                                    <Neo.Button
                                        className="btn-150 mb-3 ml-3 d-none d-sm-inline"
                                        onClick={async () => await viewModel.downloadParticipantStatement()}
                                        tooltip={"Downloads a PDF statement of the portfolio."}>Download</Neo.Button>}

                                {portfolioData &&
                                    <Neo.Button
                                        className="btn-150"
                                        buttonGroupClassName="mb-3 ml-3 d-none d-sm-inline calculate-button"
                                        menuAlignment='right'
                                        menuItems={[
                                            { text: <><div>Edit prices</div><span className="text-muted">Edit instrument prices on the current screen.</span></>, onClick: () => viewModel.portfolioData!.instrumentEditMode = !viewModel.portfolioData?.instrumentEditMode },
                                            { text: <><div>Calculator</div><span className="text-muted">Enter the advanced calculator to see what the value of your awards could be in different scenarios.</span></>, onClick: () => this.props.onCalculateClick(false) },
                                            { text: <><div>Trade</div><span className="text-muted">Begin trading the selected items below.</span></>, onClick: () => this.showTradeCalculator() }]}
                                    >Calculate</Neo.Button>}


                                {viewModel.portfolioService.showMissingExchangeRateWarning &&
                                    <Neo.Alert className="alert-tiny ml-3 mb-3" variant="warning" heading="Missing Exchange rates">
                                        Awards that cannot be converted will be shown in the award currency.
                                    </Neo.Alert>}

                                <Neo.FormGroup
                                    className="ml-3 mb-3"
                                    editorClassName="highlight"
                                    suppressLabel
                                    editorStyle={{ width: viewModel.portfolioService.currencyId ? 100 : 200 }}
                                    bind={viewModel.portfolioService.meta.currencyId}
                                    select={{ items: viewModel.portfolioData?.mainCurrencies, allowNulls: true, nullText: "Show in Unit currency", deSelectText: "Show in Unit currency" }} />
                            </div>
                        </div>

                        <Neo.Loader show={viewModel.portfolioService.exchangeRatesTask.isBusy}>
                            {viewModel.incentiveGroups.map(group => (
                                <div key={group.entityIdentifier} className="portfolio-group-container">
                                    <div className="portfolio-group-header">
                                        <div className="portfolio-group-title">
                                            {group.info.groupName}
                                        </div>
                                    </div>
                                    <div className="portfolio-group-body">
                                        {portfolioData &&
                                            <BalancesGroupedGrid category={group}
                                                vestingType={viewModel.vestingType}
                                                editMode={portfolioData.instrumentEditMode} 
                                                hasCostsAlreadyPaidGroups={viewModel.portfolioService.appData?.hasCostsAlreadyPaidGroups? true : false}/>}
                                    </div>
                                    {group.info.userDescription &&
                                        <div className="portfolio-group-header py-2 mb-2">
                                            <small className="text-heading">{group.info.userDescription}</small>
                                        </div>}
                                </div>))}
                        </Neo.Loader>
                        <div className="mt-3 text-right">
                            <Neo.Button className="btn-150 mb-3" onClick={() => this.showTradeCalculator()}>Continue</Neo.Button>
                        </div>
                    </div>}

                <div className="mt-4">
                    {<PendingTradeRequestsComponent options={{ taskRunner: viewModel.taskRunner }} taskRunner={viewModel.taskRunner} />}
                </div>
            </div>
        )
    }
}