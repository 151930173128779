import { AppServices, Security } from '@singularsystems/neo-core'
import { UserManagerSettings } from 'oidc-client-ts';
import { injectable } from 'inversify';
import { ApiConfig } from './ApiConfig';
import { INotificationServiceConfig } from '@singularsystems/neo-notifications';
import { NotificationDuration } from '../Enums/NotificationDuration';
import { AdminAppConfig } from './AdminAppConfig';

@injectable()
export class Config extends AppServices.ConfigModel {

    public static readonly silentSignInRoute = "/OidcSilentLoginRedirect";
    public static readonly loginRedirectRoute = "/OidcLoginRedirect";

    public userManagerSettings = {} as UserManagerSettings;

    public readonly enableLoadServerCss: boolean = true;
    public readonly alwaysLoadServerCss: boolean = false;

    private identityServer = { client_id: "", issuerUrl: "" };
    private apis: { [key: string]: { baseUrl: string, apiPath: string } } = {};

    private adminApp = { silentSignInUrl: "", dashboardUrl: "" };

    public AdminApp!: AdminAppConfig;
    
    public applicationInsights = { instrumentationKey: "" };

    // Api Properties
    public readonly IdentityServerApi!: ApiConfig;
    public readonly ClientsApi!: ApiConfig;
    public readonly AuthorisationServerApi!: ApiConfig;
    public readonly ParticipantsApi!: ApiConfig;
    public readonly IncentiveSchemesApi!: ApiConfig;
    public readonly PayrollsApi!: ApiConfig;
    public readonly AwardsApi!: ApiConfig;
    public readonly InterestApi!: ApiConfig;
    public readonly DividendsApi!: ApiConfig;
    public readonly NotificationsApi!: ApiConfig;
    public readonly TransactionsApi!: ApiConfig;
    public readonly ReportingApi!: ApiConfig;

    // Local Storage Keys
    public static readonly blurConfidentialValuesLocalStorageKey: string = "participant.blurConfidentialValues";

    // Authorisation
    public get authorisationServerApiPath(): string {
        return this.AuthorisationServerApi.ApiPath;
    }

    /**
     * Transforms property values loaded from config.json
     */
    public initialise() {
        for (const apiName of Object.keys(this.apis)) {
            if (this.apis.hasOwnProperty(apiName)) {
                const api = this.apis[apiName];
                this[apiName + "Api"] = new ApiConfig(api.baseUrl, api.apiPath);
            }
        }
        this.loadUserManagerSettings();
        this.AdminApp = new AdminAppConfig(this.adminApp.silentSignInUrl, this.adminApp.dashboardUrl)
    }

    private loadUserManagerSettings() {

        const appUrl = `${window.location.origin}${this.baseUrl}`;

        this.userManagerSettings = {
            client_id: this.identityServer.client_id,
            redirect_uri: `${appUrl}${Config.loginRedirectRoute}`,
            response_type: 'code',
            scope: "openid profile st_access Clients Participants Authorisation IncentiveSchemes Payrolls IdentityServerApi Awards Interest Dividends NotificationService Transactions Reporting",
            authority: this.identityServer.issuerUrl,
            silent_redirect_uri: `${appUrl}${Config.silentSignInRoute}`,
            monitorSession: false,
            metadata: Security.OidcAuthService.createIdentityServerMetadata(this.IdentityServerApi.BasePath)
        };

        // TODO: change createIdentityServerMetadata to also accept an issuer in case this is different to the base path
        this.userManagerSettings.metadata!.issuer = this.identityServer.issuerUrl;
    }

    public get notificationServerConfig(): INotificationServiceConfig {
        return {
            basePath: this.NotificationsApi.BasePath,
            apiPath: this.NotificationsApi.ApiPath,
            popupHideTime: NotificationDuration.Standard,
            appId: 2,
            allowBodyHtml: true
        }
    }
}