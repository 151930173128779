import React from 'react';
import { ITaskRunner, NeoModel } from '@singularsystems/neo-core';
import { Neo, Views } from '@singularsystems/neo-react';
import { TradeTransactionsComponent } from '../../../../Common/Components/TradeTransactionsComponent';
import TradeRequestLookup from '../../../Models/Trading/Lookups/TradeRequestLookup';
import { AppService, Types } from '../../../TransactionsTypes';
import TradeRequestDocumentsComponent from '../../../../Common/Components/TradeRequestDocumentComponent';

interface ITradeDetailComponentProps {
    taskRunner: ITaskRunner;
    tradeRequestLookup: TradeRequestLookup;
    isInModal?: boolean;
}

@NeoModel
class TradeDetailComponentVM extends Views.ViewModelBase {

    constructor(
        private apiClient = AppService.get(Types.Transactions.ApiClients.PortfolioApiClient),
        private tradeApiClient = AppService.get(Types.Transactions.ApiClients.TradeApiClient)) {
        super();
        this.getDocumentDownloadUrl = this.getDocumentDownloadUrl.bind(this);
    }

    public agreementText: string | null = null;

    public async getDocumentDownloadUrl(tradeRequestId: number) {

        return await this.taskRunner.waitForData(this.tradeApiClient.getDocumentDownloadUrl(tradeRequestId), {allowPost: true});
    }

    public async getAgreementText(tradeAgreementId: number) {

        this.agreementText = await this.taskRunner.waitForData(this.apiClient.getTradeAgreementText(tradeAgreementId));
    }
}

export class TradeDetailComponent extends Views.ViewComponentBase<ITradeDetailComponentProps, TradeDetailComponentVM> {

    constructor(props: ITradeDetailComponentProps) {
        super(props, TradeDetailComponentVM);

    }

    public render() {
        const trade = this.props.tradeRequestLookup;
        const content = <div>
            <Neo.GridLayout md={this.props.isInModal ? 1 : 2} lg={2}>
                <div>
                    <Neo.FormGroupInline display={trade.meta.tradeRequestId} label="Ref No." />
                    <Neo.FormGroupInline label="Placed" display={trade.meta.tradeDate} />
                    <Neo.FormGroupInline display={trade.meta.instrumentCode} />
                    {(trade.limitPrice !== null && trade.limitPrice > 0) && (
                        <Neo.FormGroupInline display={trade.meta.limitPrice} numProps={{ currencySymbol: trade.currencySymbol }} />
                    )}
                </div>
                <div>
                    <Neo.FormGroupInline display={trade.meta.sellQuantity} />
                    <Neo.FormGroupInline display={trade.meta.buyQuantity} />
                    <Neo.FormGroupInline display={trade.meta.statusText} />
                    <Neo.FormGroupInline display={trade.meta.buyPrice} numProps={{ currencySymbol: trade.currencySymbol }} hidden={trade.meta.buyQuantity.value <= 0} />
                </div>
            </Neo.GridLayout>

            <div className="text-right mt-2">
                {trade.tradeAgreementId &&
                    <Neo.Button size="sm" onClick={() => this.viewModel.getAgreementText(trade.tradeAgreementId!)}>View trade agreement</Neo.Button>}
            </div>
        </div>

        return (
            <div>
                {this.props.isInModal ? content :
                    <Neo.Card title="Details">
                        {content}
                    </Neo.Card>}
                <div className={this.props.isInModal ? "mt-4" : ""}>
                    <TradeTransactionsComponent extraDetails={trade.extraDetails!} />
                </div>

                <Neo.Modal bindModel={this.viewModel.meta.agreementText} size="lg" title="Trade agreement">
                    {(agreementText: string) => (
                        <div className="trade-terms" dangerouslySetInnerHTML={{ __html: agreementText }}></div>
                    )}
                </Neo.Modal>
                {trade.extraDetails && trade.extraDetails.documents.length > 0 &&
                    <TradeRequestDocumentsComponent extraDetails={trade.extraDetails} taskRunner={this.viewModel.taskRunner} getDocumentDownloadUrl={this.viewModel.getDocumentDownloadUrl} />}
            </div>
        )
    }
}