import { Attributes, Validation } from '@singularsystems/neo-core';
import ParticipantAwardLookupCriteriaBase from '../../../../../Common/Models/Base/ParticipantAwardLookupCriteriaBase';

export default class MyAwardLookupCriteria extends ParticipantAwardLookupCriteriaBase {
    static typeName = "MyAwardLookupCriteria";

    constructor() {
        super();
        this.makeObservable();
    }

    @Attributes.Nullable()
    public participantOfferId: number | null = null;

    @Attributes.Nullable()
    @Attributes.Integer()
    public participantOfferIds: number[] | null = null;

    public ignoreCancelledAwards: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New my award lookup criteria";
        } else {
            return "My Award Lookup Criteria";
        }
    }
}