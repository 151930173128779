import * as React from 'react';
import { NotifyUtils } from '@singularsystems/neo-core';
import { Neo, Views } from '@singularsystems/neo-react';
import { Observer } from 'mobx-react';

import { AppTypes as Types, AppService } from './Services/AppService';
import HeaderPanel from './Components/Application/HeaderPanel';
import NeoLogo from './assets/img/Neo-charcoal.png';
import RouteView from './Components/Application/RouteView';
import ChatbotComponent from './Components/Application/ChatbotComponent';

/* 
  Note: this must only be uncommented when adjusting styles.
  e.g. config.alwaysLoadServerCss is false
  Otherwise these styles will be loaded in addition to the server styles
*/
// import './Styles/Variable/App.scss';

import '../Transactions/ParticipantsApp/Styles/TransactionStyles.scss';
import '../Awards/ParticipantsApp/Styles/awards.scss';
import '../Participants/ParticipantsApp/Styles/ParticipantsStyles.scss';
import './Styles/Static/StaticCommon.scss';

export default class App extends React.Component {

    private appLayout = AppService.get(Types.Shared.Services.AppLayout);

    componentDidMount() {
        this.appLayout.setup();
    }

    public render() {

        return (
            <main>
                <div className="app-container">

                    {/* Top header panel */}
                    <HeaderPanel />

                    {/* Main content panel */}
                    <div>

                        <Observer>
                            {() => (
                                <Neo.Loader task={Views.ViewBase.currentView ? Views.ViewBase.currentView.taskRunner : undefined} className="page-loader" showSpinner={false} />
                            )}
                        </Observer>

                        <div className="app-content-area container" id="content-panel">
                            <React.Suspense fallback={<div>Loading...</div>}>
                                <RouteView />
                            </React.Suspense>
                        </div>

                        <div className="app-footer" id="footer-panel">
                            Powered by <img src={NeoLogo} alt="Neo" style={{ width: 60 }} />
                        </div>

                    </div>

                    <Neo.ModalContainer />
                    <Neo.ToastContainer notificationStore={NotifyUtils.store} />
                    <Neo.TooltipProvider />
                    <ChatbotComponent />
                </div>
            </main>
        );
    }
}