import { Attributes, Components, DateUtils, ITaskRunner, List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Awards } from '../../../App';
import AppDataLookup from '../Models/Portfolio/AppDataLookup';
import InstrumentLookup from '../../Common/Models/InstrumentLookup';
import { AppService, Types } from '../TransactionsTypes';
import CurrencyLookup from '../../Common/Models/CurrencyLookup';
import { ILoadOptions, IPortfolioService } from './IPortfolioService';
import { injectable } from 'inversify';
import AmountWithCurrency from '../Models/AmountWithCurrency';
import FutureAmounts from '../Models/FutureAmounts';
import TradeRequestLookup from '../Models/Trading/Lookups/TradeRequestLookup';
import ClosedPeriodCriteria from '../Models/Trading/Criteria/ClosedPeriodCriteria';

const currencyStorageKey = "participant.currencyId";
const currencyNullStorageKey = "participant.portfolioNullCurrency";

@injectable()
@NeoModel
export default class PortfolioService extends ModelBase implements IPortfolioService {

    constructor(
        private apiClient = AppService.get(Types.Transactions.ApiClients.PortfolioApiClient),
        private pricingApiClient = AppService.get(Types.Transactions.ApiClients.PricingApiClient),
        private awardsDataCache = AppService.get(Types.Awards.Services.AwardsDataCache),
        private authService = AppService.get(Types.Shared.Services.STAuthenticationService)) {
        super();
 
    }

    private lastLoadDate: Date | null = null;
    private lastParticipantId: string = "";
    private fetchCurrencyId: number | null = null;
    private suppressEvents: boolean = false;

    public appData: AppDataLookup | null = null;

    public get hasData() {
        return this.appData != null;
    }

    public hasMissingExchangeRates = false;

    public get showMissingExchangeRateWarning() {
        return this.hasMissingExchangeRates && this.currencyId !== null;
    }

    public exchangeRatesTask = AppService.get(Types.Neo.TaskRunner);
    public pendingTradesTask = AppService.get(Types.Neo.TaskRunner);

    @Attributes.OnChanged<PortfolioService>(c => c.onCurrencyChanged)
    public currencyId: number | null = null;

    public selectedCurrency = new CurrencyLookup();

    public async ensureLoaded(taskRunner: ITaskRunner, loadOptions?: ILoadOptions, onError = Components.ErrorDisplayType.ShowModal) {
        
        loadOptions = loadOptions ?? {};
        let appData = this.appData;

        if (!appData || this.lastLoadDate === null || DateUtils.difference(this.lastLoadDate, new Date()).totalMinutes > 60 || this.authService.user?.participantId !== this.lastParticipantId) {
            this.loadCurrencyFromStorage();
      
            const results = await taskRunner.waitForAll({
                instruments: this.awardsDataCache.instruments.getDataAsync(),
                incentiveSchemes: this.awardsDataCache.incentiveSchemes.getDataAsync()
            }, { errorHandling: onError });      
            const instrumentList = List.fromJSArray(InstrumentLookup, results.instruments);
            const incentiveSchemes = List.fromJSArray(Awards.IncentiveSchemeLookup, results.incentiveSchemes);
            
            var criteria = new ClosedPeriodCriteria();
            criteria.incentiveSchemeIds = incentiveSchemes.map(iss => iss.incentiveSchemeId)
            criteria.instrumentIds = instrumentList.map(iss => iss.instrumentId)
            let participantAppData = await this.apiClient.getParticipantsAppData(this.fetchCurrencyId, loadOptions.fetchPendingTrades === true, criteria.toQueryObject());
            
          appData = AppDataLookup.fromJSObject<AppDataLookup>(participantAppData.data);
            appData.setup(instrumentList, incentiveSchemes);

            if (loadOptions.fetchPendingTrades === true) {
                appData.setupPendingTradeRequests();
            }
            
            this.lastLoadDate = new Date();
            this.lastParticipantId = this.authService.user?.participantId ?? "";
        }
        
        if (loadOptions.fetchPendingTrades && !appData.pendingTradeRequestsLoaded) {
            const result = await this.pendingTradesTask.waitFor(this.apiClient.getPendingTradeRequests());
            appData.pendingTradeRequests = List.fromJSArray(TradeRequestLookup, result.data);
            appData.setupPendingTradeRequests();
        }

        const prefersNull = window.localStorage.getItem(currencyNullStorageKey) !== "false";
        this.suppressEvents = true;
        if (prefersNull && loadOptions.allowNullCurrency) {
            this.currencyId = null;
        } else {
            this.currencyId = this.fetchCurrencyId ?? appData.settings.baseCurrencyId;
        }
        this.suppressEvents = false;
        this.setSelectedCurrency(appData);

        appData.instrumentEditMode = false;

        this.appData = appData;

        return this.appData!;
    }

    public get vestedProfits() {
        return this.getProfitAmounts(false);
    }

    public get totalProfits() {
        return this.getProfitAmounts(true);
    }

    public get futureAmounts() {        
        if (this.appData && !this.hasMissingExchangeRates) {
            // Excluding values of hidden schemes
            const visibleSchemes = this.appData!.trancheBalances.filter(c => !c.incentiveScheme.hideFromParticipants)
            const now = new Date();
            const endOfThisYear = new Date(now.getFullYear(), 11, 31);
            let startYear = 0;
            if (!visibleSchemes.some(c => !c.isVested && c.canTradeDate <= endOfThisYear)) {
                // Nothing else vesting this year, look forward to next year.
                startYear = 1;
            }

            const year1 = new Date(now.getFullYear() + startYear, 11, 31),
                year2 = new Date(now.getFullYear() + startYear + 1, 11, 31),
                year3 = new Date(now.getFullYear() + startYear + 2, 11, 31);

            const futureAmounts = new FutureAmounts(year1.getFullYear().toString(), year2.getFullYear().toString(), year3.getFullYear().toString() + "+");
    
            for (let tranche of visibleSchemes) {
                if (tranche.instrument.rate !== null ) {
                    if (tranche.isVested) {
                        futureAmounts.vestedValue += tranche.limitLossForTotal(c => c.profitLossConverted);
                    } else if (tranche.canTradeDate <= year1) {
                        futureAmounts.yearPlus1Value += tranche.limitLossForTotal(c => c.profitLossConverted);
                    } else if (tranche.canTradeDate <= year2) {
                        futureAmounts.yearPlus2Value += tranche.limitLossForTotal(c => c.profitLossConverted);
                    } else {
                        futureAmounts.yearPlus3Value += tranche.limitLossForTotal(c => c.profitLossConverted);
                    }
                    futureAmounts.allTimeValue += tranche.limitLossForTotal(c => c.profitLossConverted);
                }
            }

            return futureAmounts;
        }
        return null;
    }

    public expire() {
        this.lastLoadDate = null;
    }

    private loadCurrencyFromStorage() {
        const currencyId = window.localStorage.getItem(currencyStorageKey);
        if (currencyId) {
            this.fetchCurrencyId = parseInt(currencyId);
        }
    }

    private getProfitAmounts(all: boolean) {
        if (this.selectedCurrency) {
            const selectedCurrency = this.selectedCurrency.symbol;

            return this.appData?.trancheBalances.filter(c => (all ||c.isVested) && !c.incentiveScheme.hideFromParticipants)
                .groupBy(c => c.instrument.rate === null ? c.currencySymbol : selectedCurrency, (key, i, details) => 
                    new AmountWithCurrency(details.sum(d => d.limitLossForTotal(c => c.profitLossConverted)), key)) ?? [];
        } else {
            return [];
        }
    }
    
    private async onCurrencyChanged() {
        if (this.appData && !this.suppressEvents) {
            window.localStorage.setItem(currencyNullStorageKey, (this.currencyId === null).toString());

            if (this.currencyId) {
                window.localStorage.setItem(currencyStorageKey, this.currencyId?.toString() ?? "");

                if (this.currencyId !== this.fetchCurrencyId) {
                    const result = await this.exchangeRatesTask.waitFor(this.pricingApiClient.getExchangeRates(this.currencyId));
                    this.appData.exchangeRates.set(result.data);
                    this.fetchCurrencyId = this.currencyId;
                }
            }
   
            this.setSelectedCurrency(this.appData);
        }
    }

    private setSelectedCurrency(appData: AppDataLookup) {
        if (this.currencyId) {
            this.selectedCurrency = appData.mainCurrencies.find(c => c.currencyId === this.currencyId)!;
        }
        this.hasMissingExchangeRates = appData.setInstrumentRates(this.currencyId ? this.selectedCurrency : null);
    }
}