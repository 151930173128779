import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, AppTypes } from 'st-app/Services/AppService';
import AwardDocumentSetLookup from '../../Models/AwardDocuments/Queries/AwardDocumentSetLookup';
import AwardDocumentLookup from '../../Models/AwardDocuments/Queries/AwardDocumentLookup';
import ParticipantOfferAwardDocumentLookup from '../../Models/AwardDocuments/Queries/ParticipantOfferAwardDocumentLookup';
import FileDescriptor from '../../Models/Files/FileDescriptor';
import { AwardSummaryDocumentType } from '../../Models/Enums/AwardSummaryDocumentType';
import AwardDocumentSetLookupCriteria from '../../Models/AwardDocuments/Queries/AwardDocumentSetLookupCriteria';

export interface IParticipantAwardDocumentInfo {
    participantOfferId: number | null;
    participantOfferAwardDocumentId: number | null;
    incentiveSchemeDocumentId: number | null;
}

export interface IAwardDocumentInfo {
    incentiveSchemeId: number | null;
    incentivePlanId: number | null;
    incentiveSchemeDocumentId: number | null;
    awardDocumentSetId: number | null;
    awardDocumentId: number;
    downloadFileDescriptor: FileDescriptor | null;
}

export interface IAwardDocumentsQueryApiClient {

    /**
     * This method will return the primary, secondary, or archived award document sets for the current tenant
     * @param criteria Filter criteria.
     * @returns A collection of document sets
     */
     specificDocumentSetLookup(criteria: Model.PartialPlainNonTrackedObject<AwardDocumentSetLookupCriteria>): AxiosPromise<Array<Model.PlainObject<AwardDocumentSetLookup>>>;

    /**
     * This method will return the award documents for a specific awardDocumentSetId
     * @param awardDocumentSetId The award document set id to fetch the documents for
     * @returns A collection of documents that match the given criteria
     */
    documentsLookup(awardDocumentSetId: number): AxiosPromise<Array<Model.PlainObject<AwardDocumentLookup>>>;

    /**
     * Will render the Html by merging the Participant Offer info into the template and return the generated template
     * @param participantId The participant id to render
     * @param participantOfferId The participant offer id to render
     * @param awardDocumentId The award document id (if not the Award Summary)
     * @returns A generated participant template
     */
    generateTemplate(participantId: string, participantOfferId: number, awardDocumentId: number): AxiosPromise<string>;

    /**
     * Will render the Html by merging the first Participant Offer info into the template and return the generated template
     * @param awardPrepId The award prep id to render
     * @param awardSummaryDocumentType The award summary document type
     * @param awardDocumentId The award document id (if not the Award Summary)
     * @returns A generated participant template
     */
    generateFirstParticipantTemplate(awardPrepId: number, awardSummaryDocumentType: AwardSummaryDocumentType, awardDocumentId: number): AxiosPromise<string>;

    /**
     * Uploads a picture.
     * @param awardDocumentSetId The specific award document set id
     * @param awardDocumentId The specific award document id
     * @param fileDescriptorId The file descriptor
     * @returns Status.
     */
    getDocumentDownloadUrl(awardDocumentSetId: number, awardDocumentId: number, fileDescriptorId: string): AxiosPromise<string>;

    /**
     * Gets the incentive scheme document document download url.
     * @param incentiveSchemeId The incentive scheme id
     * @param incentivePlanId The incentive plan id
     * @param incentiveSchemeDocumentId The incentive scheme document id
     * @param fileDescriptorId The file descriptor
     * @param fileStore The file store
     * @returns Document download url.
     */
    getIncentiveSchemeDocumentDownloadUrl(incentiveSchemeId: number | null, incentivePlanId: number | null, incentiveSchemeDocumentId: number, fileDescriptorId: string): AxiosPromise<string>;

    /**
     * This method will return the participant award offer documents for a specific participantOfferId
     * It will only work if requested by the participant.
     * @param participantOfferId The participant offer for which to fetch the documents for
     * @returns A collection of questionnaires that match the given criteria
     */
    participantDocumentsLookup(participantOfferId: number): AxiosPromise<Array<Model.PlainObject<ParticipantOfferAwardDocumentLookup>>>;

    /**
     * This method will return the participant award offer document for a specific participantOfferId and participantOfferAwardDocumentId.
     * It will only work if requested by the participant.
     * @param participantOfferId The participant offer for which to fetch the documents for.
     * @param participantOfferAwardDocumentId The document id.
     * @returns A collection of questionnaires that match the given criteria
     */
    participantDocumentLookup(participantOfferId: number, participantOfferAwardDocumentId: number): AxiosPromise<Model.PlainObject<ParticipantOfferAwardDocumentLookup>>;

    /**
     * This method will return the incentive scheme document for a specific incentiveSchemeDocumentId.
     * It will only work if requested by a participant.
     * @param incentiveSchemeDocumentId The incentive scheme document id.
     * @returns Incentive Scheme document information
     */
    incentiveSchemeDocumentLookup(incentiveSchemeDocumentId: number): AxiosPromise<Model.PlainObject<ParticipantOfferAwardDocumentLookup>>;

    /**
     * Will get a One Time token for the specified award prep id
     * @param pdfGenerationService The pdf generation service
     * @param participantOfferId The participant offer id
     * @param participantOfferAwardDocumentId The participant offer award document id (0 = Get Award Summary Html)
     * @param isAcceptedAwardSummary The accepted award summary
     * @returns The One Time token
     */
    getHtmlDocumentPdfDownloadToken(participantOfferId: number, participantOfferAwardDocumentId: number, isAcceptedAwardSummary: boolean): AxiosPromise<string>;

    /**
     * Will get a One Time token for the specified participant award document.
     * @param pdfGenerationService The pdf generation service
     * @param awardPrepId The award prep id
     * @param awardSummaryDocumentType The award summary document type
     * @param awardDocumentId The award document id
     * @returns The One Time token
     */
    getAwardSummaryHtmlDocumentPdfDownloadToken(awardPrepId: number, awardSummaryDocumentType: AwardSummaryDocumentType, awardDocumentId: number | null): AxiosPromise<string>;

    /**
     * Gets the participant award document in Pdf format.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @param awardPrepId The award prep id
     * @param token The sas token for accessing the resource
     * @returns A pdf document
     */
    getDownloadAwardSummaryHtmlDocumentPdfUrl(awardPrepId: number, token: string): string;

    /** 
     * Will get a One Time token for the specified award prep id zip download.
     * @param pdfGenerationService The pdf generation service
     * @param awardPrepId The award prep id
     * @returns The One Time token
     */
    getAllAwardSummaryDocumentZipDownloadToken(awardPrepId: number): AxiosPromise<string>;

    /** 
     * Gets all the participant award documents in Pdf format in a Zip file.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @param awardPrepId The award prep id
     * @param token token for the specified award zip download
     * @returns A pdf document
     */
    getDownloadAllAwardSummaryDocumentsZipUrl(awardPrepId: number, token: string): string;

    /**
     * Gets the award excel export
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @param participantOfferId The participant offer id
     * @param participantOfferAwardDocumentId The participant offer award document id
     * @param token The sas token for accessing the resource
     * @returns An excel document with all the award information
     */
    getDownloadHtmlDocumentPdfUrl(participantOfferId: number, participantOfferAwardDocumentId: number, token: string): string;

    /**
     * Gets a unique name for a secondary award document set
     * @param awardDocumentSetName  The name of the award document set
     * @returns A unique name for a secondary award document set
     */
    getUniqueDocumentSetName(awardDocumentSetName: string): AxiosPromise<string>;

    /**
     * This method will generate and return  the participant accepted award offer document for a specific participantOfferId
     * @param awardPrepId The award prep id/param>
     * @param participantOfferId The participant offer id/param>
     */
    generateAcceptedAwardsSummaryAsync(participantOfferId: number, awardPrepId: number): AxiosPromise<Model.PlainObject<ParticipantOfferAwardDocumentLookup>>;

    // Client only properties / methods

    documentLookup(document: IParticipantAwardDocumentInfo): AxiosPromise<Model.PlainObject<ParticipantOfferAwardDocumentLookup>>;

    getDownloadUrl(document: IAwardDocumentInfo): AxiosPromise<string>;

    getSurveyDocumentDownloadUrl(surveyParticipantDocumentId: number, fileDescriptorId: string): AxiosPromise<string>;
}

@injectable()
export default class AwardDocumentsQueryApiClient extends Data.ApiClientBase implements IAwardDocumentsQueryApiClient {

    constructor(config = AppService.get(AppTypes.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/award-documents/query`);
    }

    public specificDocumentSetLookup(criteria: Model.PartialPlainNonTrackedObject<AwardDocumentSetLookupCriteria>): AxiosPromise<Array<Model.PlainObject<AwardDocumentSetLookup>>> {
        return this.axios.get(`${this.apiPath}/specific-document-sets?${Utils.getQueryString(criteria)}`);
    }

    public documentsLookup(awardDocumentSetId: number): AxiosPromise<Array<Model.PlainObject<AwardDocumentLookup>>> {
        return this.axios.get(`${this.apiPath}/documents/${awardDocumentSetId}`);
    }

    public generateTemplate(participantId: string, participantOfferId: number, awardDocumentId: number): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/template/${encodeURIComponent(participantId)}/${participantOfferId}?awardDocumentId=${awardDocumentId}`);
    }

    public generateFirstParticipantTemplate(awardPrepId: number, awardSummaryDocumentType: AwardSummaryDocumentType, awardDocumentId: number | null): AxiosPromise<string> {
        let queryString = "";
        if (awardDocumentId) {
            queryString = `?awardDocumentId=${awardDocumentId}`;
        }

        if (awardSummaryDocumentType) {
            if (queryString) {
                queryString += `&awardSummaryDocumentType=${awardSummaryDocumentType}`;
            } else {
                queryString = `?awardSummaryDocumentType=${awardSummaryDocumentType}`;
            }
        }
        return this.axios.get(`${this.apiPath}/template/example/${awardPrepId}${queryString}`);
    }

    public getDocumentDownloadUrl(awardDocumentSetId: number, awardDocumentId: number, fileDescriptorId: string): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/document/download-url/${awardDocumentSetId}/${awardDocumentId}/${encodeURIComponent(fileDescriptorId)}`);
    }

    public getIncentiveSchemeDocumentDownloadUrl(incentiveSchemeId: number | null, incentivePlanId: number | null, incentiveSchemeDocumentId: number, fileDescriptorId: string): AxiosPromise<string> {
        let queryString = "";
        if (incentiveSchemeId) {
            queryString = `incentiveSchemeId=${incentiveSchemeId}`;
        } else if (incentivePlanId) {
            queryString = `incentivePlanId=${incentivePlanId}`;
        }

        return this.axios.get(`${this.apiPath}/document/download-url/${incentiveSchemeDocumentId}/${encodeURIComponent(fileDescriptorId)}?${queryString}`);
    }

    public participantDocumentsLookup(participantOfferId: number): AxiosPromise<Array<Model.PlainObject<ParticipantOfferAwardDocumentLookup>>> {
        return this.axios.get(`${this.apiPath}/participant-award/${participantOfferId}/documents`);
    }

    public participantDocumentLookup(participantOfferId: number, participantOfferAwardDocumentId: number): AxiosPromise<Model.PlainObject<ParticipantOfferAwardDocumentLookup>> {
        return this.axios.get(`${this.apiPath}/participant-award/${participantOfferId}/document/lookup/${participantOfferAwardDocumentId}`);
    }

    public incentiveSchemeDocumentLookup(incentiveSchemeDocumentId: number): AxiosPromise<Model.PlainObject<ParticipantOfferAwardDocumentLookup>> {
        return this.axios.get(`${this.apiPath}/participant-award/document/incentive-scheme/${incentiveSchemeDocumentId}`);
    }

    public getHtmlDocumentPdfDownloadToken(participantOfferId: number, participantOfferAwardDocumentId: number, isAcceptedAwardSummary: boolean): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/participant-award/${participantOfferId}/document/download-token/${participantOfferAwardDocumentId}/${isAcceptedAwardSummary}`);
    }

    public getAwardSummaryHtmlDocumentPdfDownloadToken(awardPrepId: number, awardSummaryDocumentType: AwardSummaryDocumentType, awardDocumentId: number | null): AxiosPromise<string> {
        let queryString = "";
        if (awardDocumentId) {
            queryString = `?awardDocumentId=${awardDocumentId}`;
        }
        if (awardSummaryDocumentType) {
            if (queryString) {
                queryString += `&awardSummaryDocumentType=${awardSummaryDocumentType}`;
            } else {
                queryString = `?awardSummaryDocumentType=${awardSummaryDocumentType}`;
            }
        }
        return this.axios.get(`${this.apiPath}/award-summary-token/${awardPrepId}${queryString}`);
    }

    public getDownloadAwardSummaryHtmlDocumentPdfUrl(awardPrepId: number, token: string): string {
        return `${this.apiPath}/award-summary-download/${awardPrepId}?token=${encodeURIComponent(token)}`;
    }

    public getAllAwardSummaryDocumentZipDownloadToken(awardPrepId: number): AxiosPromise<string> {
        let url = AppService.get(AppTypes.Shared.Config).AwardsApi.ApiPath;
        return this.axios.get(`${this.apiPath}/all-award-summary-token/${awardPrepId}`);
    }

    public getDownloadAllAwardSummaryDocumentsZipUrl(awardPrepId: number, token: string): string {
        return `${this.apiPath}/all-award-summaries-download/${awardPrepId}?token=${encodeURIComponent(token)}`;
    }

    public getDownloadHtmlDocumentPdfUrl(participantOfferId: number, participantOfferAwardDocumentId: number, token: string): string {
        return `${this.apiPath}/participant-award/${participantOfferId}/document/download-pdf/${participantOfferAwardDocumentId}?token=${encodeURIComponent(token)}`;
    }

    public getUniqueDocumentSetName(awardDocumentSetName: string): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/unique-award-document-set-name/${encodeURIComponent(awardDocumentSetName)}`);
    }

    public generateAcceptedAwardsSummaryAsync(participantOfferId: number, awardPrepId: number): AxiosPromise<Model.PlainObject<ParticipantOfferAwardDocumentLookup>> {
        return this.axios.get(`${this.apiPath}/participant-award/${awardPrepId}/${participantOfferId}/accepted-award`);
    }

    // Client only properties / methods

    public documentLookup(document: IParticipantAwardDocumentInfo) {
        if (document.incentiveSchemeDocumentId) {
            return this.incentiveSchemeDocumentLookup(document.incentiveSchemeDocumentId);
        }
        else {
            return this.participantDocumentLookup(document.participantOfferId!, document.participantOfferAwardDocumentId!);
        }
    }

    public getDownloadUrl(document: IAwardDocumentInfo) {
        if (document.incentiveSchemeDocumentId) {
            return this.getIncentiveSchemeDocumentDownloadUrl(document.incentiveSchemeId, document.incentivePlanId, document.incentiveSchemeDocumentId, document.downloadFileDescriptor!.fileDescriptorId!);
        }
        else {
            return this.getDocumentDownloadUrl(document.awardDocumentSetId!, document.awardDocumentId, document.downloadFileDescriptor!.fileDescriptorId!);
        }
    }

    public getSurveyDocumentDownloadUrl(surveyParticipantDocumentId: number, fileDescriptorId: string): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/survey-document/download-url/${surveyParticipantDocumentId}/${encodeURIComponent(fileDescriptorId)}`);
    }
}