import { ModelBase, NeoModel } from '@singularsystems/neo-core'
import { injectable } from 'inversify';
import { AppService, AppTypes } from '../../../App/Services/AppService';

@injectable()
@NeoModel
export default class ChatbotService extends ModelBase {

    public constructor(
        public taskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        private clientsApiClient = AppService.get(AppTypes.Clients.ApiClients.ClientsApiClient),
    ) {
        super();
    }

    public chatbotURL: string = '';

    public async initialise() {
        const urlResponse = await this.clientsApiClient.getChatbotURL();
        this.chatbotURL = urlResponse.data;
    }
}