import React from 'react';
import { Views } from '@singularsystems/neo-react';
import PortfolioVM from './PortfolioVM';
import { observer } from 'mobx-react';
import { TradeComponent } from './Components/TradeComponent';
import { BalancesComponent } from './Components/BalancesComponent';
import { CalculateComponent } from './Components/CalculateComponent';
import { IParticipantsAppView } from '../../../../App/Components/Application/IParticipantsAppView';

class Params {
    calculate = {};
    trade = {};
}

@observer
export default class PortfolioView extends Views.ViewBase<PortfolioVM, Params> implements IParticipantsAppView {
    static params = new Params();

    constructor(props: unknown) {
        super("Portfolio", PortfolioVM, props);
    }

    public async viewParamsUpdated() {
        if (this.viewParams.calculate.value) {
            if (this.viewParams.calculate.value === "trade") {
                if (await this.viewModel.enterCalculationMode(true)) {
                    this.viewParams.calculate.description = "Trade";
                } else {
                    this.viewParams.calculate.value = null;
                }
            } 

            if (this.viewParams.calculate.value === "calculator") {
                if (await this.viewModel.enterCalculationMode(false)) {
                    this.viewParams.calculate.description = "Calculator";
                } else {
                    this.viewParams.calculate.value = null;
                }
            }
            
            if (this.viewParams.trade.value) {
                if (this.viewModel.tradeVM) {
                    this.viewParams.trade.description = "Confirm";
                } else {
                    this.viewParams.trade.value = null;
                }
            } else {
                this.viewModel.tradeVM = null;
            }
        } else {
            this.viewModel.clearCalculateMode();
            window.scrollTo({ top: 0 });
        }
    }

    public showBreadcrumb() {
        return this.viewModel.isCalculateMode && !(this.viewModel.tradeVM?.hasSavedTrades);
    }

    public get headerTitle() {
        return "My Portfolio";
    }

    public render() {

        return (
            <div className="portfolio-view">
                {this.viewModel.portfolioData &&
                    <div>
                        {!this.viewModel.isCalculateMode &&
                            <BalancesComponent viewModel={this.viewModel} onCalculateClick={(forTrading) => this.viewParams.calculate.value = forTrading ? "trade" : "calculator"} />}

                        {this.viewModel.isCalculateMode && this.viewModel.tradeVM === null &&
                            <CalculateComponent viewModel={this.viewModel} onBeginTrade={() => this.viewParams.trade.value = "confirm"} backToPortfolio={() => this.viewParams.calculate.value = null} />}
                    </div>}

                { /* Trading */
                    this.viewModel.tradeVM &&
                    <TradeComponent 
                        viewModel={this.viewModel.tradeVM} 
                        backToCalculator={() => this.viewParams.trade.value = null} 
                        backToPortfolio={() => this.viewParams.calculate.value = null} 
                        hasCostsAlreadyPaidGroups={this.viewModel.portfolioService.appData?.hasCostsAlreadyPaidGroups ?? false} 
                    />
                }
            </div>
        );
    }
}