import { AppServices } from '@singularsystems/neo-core';
import ChatbotService from './Services/ChatbotService';
import { IClientsApiClient } from './ApiClients/ClientsApiClient';
import { IImagesQueryApiClient } from './ApiClients/ImagesQueryApiClient';
import { IMainCurrenciesApiClient } from './ApiClients/MainCurrenciesApiClient';
import { IStylesQueryApiClient } from './ApiClients/StylesQueryApiClient';
import { ITenantDocumentsQueryApiClient } from './ApiClients/TenantDocumentsQueryApiClient';
import { IStyleService } from './Services/StyleService';


// Symbols to expose outside of this module
export const ClientsSharedTypes = {
    ApiClients: {
        ImagesQueryApiClient: new AppServices.ServiceIdentifier<IImagesQueryApiClient>("Clients.ApiClients.ImagesQueryApiClient"),
        AdminStylesQueryApiClient: new AppServices.ServiceIdentifier<IStylesQueryApiClient>("Clients.ApiClients.StylesQueryApiClient"),
        MainCurrenciesApiClient: new AppServices.ServiceIdentifier<IMainCurrenciesApiClient>("Clients.ApiClients.MainCurrenciesApiClient"),
        TenantDocumentsQueryApiClient: new AppServices.ServiceIdentifier<ITenantDocumentsQueryApiClient>("Clients.ApiClients.TenantDocumentsQueryApiClient"),
        ClientsApiClient: new AppServices.ServiceIdentifier<IClientsApiClient>("Clients.ApiClients.ClientsSharedApiClient")
    },
    Services: {
        StyleService: new AppServices.ServiceIdentifier<IStyleService>("Clients.Services.StyleService"),
        ChatbotService: new AppServices.ServiceIdentifier<ChatbotService>("Clients.Services.ChatbotService"),
    }
}
