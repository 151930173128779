import { Data, NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../AwardsTypes';
import MyAwardLookup from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup';
import MyAwardLookupCriteria from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupCriteria';
import MyAwardsVMBase from './MyAwardsVMBase';

@NeoModel
export default class MyAwardsVM extends MyAwardsVMBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner)) {

        super(taskRunner);
    }

    private acceptedAwardsCriteria = new MyAwardLookupCriteria();

    public acceptedAwardsPageManager = new Data.PageManager(this.acceptedAwardsCriteria, MyAwardLookup, (request) => this.myAwardsApiClient.getAcceptedAwards(request, null), {
        pageSize: 6,
        initialTaskRunner: this.taskRunner,
        sortBy: "awardDate",
        sortAscending: false,
        afterFetch: (data) => this.setupAcceptedAwardsData(data)
    });

    public hasPendingAwards = false;
    public hasAcceptedAwards = false;

    public async initialise() {
        
        // get the base to start loading
        const resultTask = this.taskRunner.waitFor(super.initialise());

        this.myAwardInfoTaskRunner.run(async () => {
            const result = await this.myAwardsApiClient.getPendingAwardInfo();
            this.myAwardsInfoLookup.set(result.data);
        });
        
        // then start loading the pages
        this.pendingAwardsPageManager.refreshData();
        this.acceptedAwardsCriteria.ignoreCancelledAwards = true;
        this.acceptedAwardsPageManager.refreshData();

        // wait for the initial task to finish loading
        await resultTask;
        this.initialising = true;

        // then try setup the data again
        await this.setupPendingAwardsData(this.pendingAwardsPageManager.data);
        this.setupAcceptedAwardsData(this.acceptedAwardsPageManager.data);

        this.initialising = false;
    }

    protected async setupPendingAwardsData(data: MyAwardLookup[]) {
        await super.setupPendingAwardsData(data);

        this.hasPendingAwards = data.length > 0;
    }

    private async setupAcceptedAwardsData(data: MyAwardLookup[]) {
        await this.setupData(data);
        
        this.hasAcceptedAwards = data.length > 0;
    }
}